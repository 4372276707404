@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: #378071;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    background-color: black;
  }
}

@layer components {
  .button-50 {
    @apply inline-block border border-[var(--primary)] bg-black text-white font-normal text-sm leading-5 shadow-md cursor-pointer;
    appearance: button;
    background-image: none;
    box-shadow: 4px 4px 0 0 var(--primary), 4px 4px 0 1px #000000;
    padding: 4px 4px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
  }

  .button-50:focus {
    text-decoration: none;
  }

  .button-50:hover {
    text-decoration: none;
  }

  .button-50:active {
    box-shadow: 0 3px 5px inset rgba(0, 0, 0, .125);
    outline: 0;
  }

  .button-50:not([disabled]):active {
    box-shadow: 2px 2px 0 0 var(var--primary), 2px 2px 0 1px #000;
    transform: translate(6px, 6px);
  }

  @media (min-width: 768px) {
    .button-50 {
      padding: 4px 4px;
    }
    .button-nav {
      padding: 4px 4px;
    }
  }
  /* navigation button style */
  .button-nav {
    @apply inline-block bg-white text-primary font-normal text-xl leading-5 cursor-pointer;
    appearance: button;
    background-image: none;
    /* box-shadow: 4px 4px 0 0 #357266, 4px 4px 0 1px #ffffff; */
    padding: 4px 4px;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
  }

  .button-nav:focus {
    text-decoration: none;
  }

  .button-nav:hover {
    text-decoration: none;
  }

  .button-nav:active {
    /* box-shadow: 0 3px 5px inset rgba(0, 0, 0, .125); */
    outline: 0;
  }

  .button-nav:not([disabled]):active {
    /* box-shadow: 2px 2px 0 0 #357266, 2px 2px 0 1px #000; */
    transform: translate(6px, 6px);
  }
}
@layer utilities {
  .h-content {
    height: calc(var(--vh, 1vh) * 100 - 6rem);
  }
}